import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";
import { Badge } from "react-bootstrap";
import { Column } from "react-table";
import { dateFormat, CustomCurrency, Currency } from "../../utils";

export const columns: Column[] = [
	{
		accessor: "boostId",
		Header: "Id",
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/boosts/${row.original.boostRefNumber}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.boostId}
			</a>
		),
	},
	{
		accessor: "invoiceRefNumber",
		Header: "Invoice Ref",
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/invoices/${row.original.invoiceRefNumber}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.invoiceRefNumber}
			</a>
		),
	},
	{ accessor: "userId", Header: "User Id" },
	{ accessor: "userName", Header: "Name" },
	{ accessor: "boostRefNumber", Header: "Boost Ref" },
	{
		accessor: "status",
		Header: "Boost Status",

		Cell: ({ value }) => (
			<Badge className="w-100 py-2">
				<p
					className="p-0 m-0"
					style={{
						fontSize: "0.85rem",
						letterSpacing: "0.05rem",
						fontWeight: 500,
					}}
				>
					{value}
				</p>
			</Badge>
		),
	},
	{
		accessor: "boostStartingDate",
		Header: "Boost Start",

		Cell: ({ value }) => (
			<div className="text-nowrap">{dateFormat(new Date(value))}</div>
		),
	},
	{
		accessor: "boostEndDate",
		Header: "Boost End",

		Cell: ({ value }) => (
			<div className="text-nowrap">{dateFormat(new Date(value))}</div>
		),
	},
	{
		Header: "Duration (days)",
		accessor: "id",

		Cell: ({ row }) => (
			<>
				{dayjs(new Date(row.values.boostEndDate)).diff(
					new Date(row.values.boostStartingDate),
					"days"
				)}
			</>
		),
	},
	{
		accessor: "boostRewardReturn",
		Header: "Interest (%)",
		Cell: ({ value }) => (
			<div className="text-nowrap">
				{Currency((value * 100).toFixed(2), {
					symbol: "%",
					pattern: "# !",
				})}
			</div>
		),
	},
	{
		accessor: "boostPercentage",
		Header: "Boost %",
		Cell: ({ value }) => (
			<div className="text-nowrap">
				{Currency(value.toFixed(2), {
					symbol: "%",
					pattern: "# !",
				})}
			</div>
		),
	},
	{
		accessor: "boostAmount",
		Header: "Boost Amount",

		Cell: ({ value, row }: { value: string; row: any }) => (
			<div className="text-nowrap">
				{`${row.original.currency} ${Currency(value, { symbol: "" })}`}
			</div>
		),
	},
	{
		accessor: "",
		Header: "Total Rewards",

		Cell: ({ row }: any) => (
			<div className="text-nowrap">{`${row.original.currency} ${Currency(
				row.original.accumulatedCurrentRewardAmount,
				{ symbol: "" }
			)}`}</div>
		),
	},
	{
		accessor: "accumulatedCurrentRewardAmount",
		Header: (
			<div className="text-nowrap d-flex gap-2">
				Total Amount
				<Tooltip title="(Boost + Rewards)">
					<InfoIcon />
				</Tooltip>
			</div>
		),
		Cell: ({ value, row }: any) => (
			<div className="text-nowrap">{`${row.original.currency} ${CustomCurrency(
				row.original.boostAmount,
				{ symbol: "" }
			)
				.add(value)
				.format()}`}</div>
		),
	},
	{
		accessor: "autoReboost",
		Header: "Auto Reboost",
		Cell: ({ value }) => <span>{value.toString()}</span>,
	},
];
