import { formatCurrency } from "../../../modules/helpers";
import { reportDateFormat } from "../../../utils";

export const advanceTableHeadRowArray = [
	{
		label: "Date",
		style: { width: "90px" },
		render: (row: any) => reportDateFormat(row?.dateOfTransaction),
	},
	{
		label: "Invoice ID",
		style: { width: "100px" },
		render: (row: any) => row?.invoiceNumber,
	},
	{
		label: "Project Name",
		style: {
			width: "220px",
		},
		render: (row: any) => row?.invoiceProjectName,
	},
	{
		label: "Type",
		style: { width: "50px", textAlign: "center" },
		render: (row: any) =>
			row?.advanceSponsoredType?.toLowerCase() === "sponsored" ? "S" : "NS",
	},
	{
		label: "Freelancer Email",
		style: { width: "140px" },
		render: (row: any) => row?.freelancerEmail,
	},
	{
		label: "Advance Amount",
		style: { width: "110px", textAlign: "right" },
		render: (row: any) =>
			`${row?.billLineItemCurrency} ${formatCurrency(row?.transactionAmount)}`,
	},
	{
		label: "Fee",
		style: { width: "80px", textAlign: "right" },
		render: (row: any) =>
			`${row?.billLineItemCurrency} ${formatCurrency(row?.transactionFee)}`,
	},
];

export const paymentTableHeadRowArray = [
	{
		label: "Date",
		style: { width: "90px" },
		render: (row: any) => reportDateFormat(row?.dateOfTransaction),
	},
	{
		label: "Invoice ID",
		style: { width: "100px" },
		render: (row: any) => row?.invoiceNumber,
	},
	{
		label: "Project Name",
		style: {
			width: "190px",
		},
		render: (row: any) => row?.invoiceProjectName,
	},
	{
		label: "Payment Ref",
		style: {
			width: "80px",
		},
		render: (row: any) => row?.paymentReference,
	},
	{
		label: "Freelancer Email",
		style: { width: "140px" },
		render: (row: any) => row?.freelancerEmail,
	},
	{
		label: "Payment Amount",
		style: { width: "110px", textAlign: "right" },
		render: (row: any) =>
			`${row?.billLineItemCurrency} ${formatCurrency(row?.transactionAmount)}`,
	},
	{
		label: "Fee",
		style: { width: "80px", textAlign: "right" },
		render: (row: any) =>
			`${row?.billLineItemCurrency} ${formatCurrency(row?.transactionFee)}`,
	},
];

export const billItemColumn = [
	{
		title: "Description",
		dataIndex: "billLineItemDescription",
		key: "billLineItemDescription",
	},
	{
		title: "Quantity",
		dataIndex: "billLineItemQuantity",
		key: "billLineItemQuantity",
	},
	{
		title: "Rate",
		dataIndex: "billLineItemRate",
		key: "billLineItemRate",
		render: (amount: any, row: any) =>
			`${row.billLineItemCurrency} ${formatCurrency(amount)}`,
	},
];
