import currency from "currency.js";
import { Column } from "react-table";
import { Currency, dateFormat } from "../../utils";
import StatusBadge from "../../components/StatusBadge";

export const tableColumns: Column[] = [
	{
		Header: "Advance Ref",
		accessor: "referenceNumber",
	},
	{
		Header: "Invoice No.",
		accessor: "invoiceNumber",
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/invoices/${row.original.invoiceReference}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.invoiceNumber}
			</a>
		),
	},
	{
		Header: "Business",
		accessor: "businessName",
	},
	{
		Header: "Email",
		accessor: "freelancerEmail",
	},
	{
		Header: "Type",
		accessor: "advanceType",
		Cell: ({ value }: any) => <>{value === "sponsored" ? "S" : "NS"} </>,
	},
	{
		Header: "Currency",
		accessor: "currency",
	},
	{
		Header: " Eligible Amount",
		accessor: "eligibleAdvanceAmount",
		Cell: ({ value }: any) => <>{Currency(value, { symbol: "" })}</>,
	},
	{
		Header: "Fee",
		accessor: "advanceFee",
		Cell: ({ value }: any) => <>{Currency(value, { symbol: "" })}</>,
	},
	{
		Header: "Status",
		accessor: "advanceStatus",
		Cell: ({ value }) => <StatusBadge status={value} variant={"primary"} />,
	},
	{
		Header: "Advance Created Date",
		accessor: "createdAt",
		Cell: ({ value }: any) => <>{dateFormat(new Date(value))}</>,
	},
	{
		Header: "Advanced Date",
		accessor: "advanceAt",
		Cell: ({ value }: any) => (
			<>
				{value ? (
					dateFormat(new Date(value))
				) : (
					<div className="text-center">-</div>
				)}
			</>
		),
	},
	{
		Header: "Bill Date",
		accessor: "billDate",
		Cell: ({ value }: any) => (
			<>
				{value ? (
					dateFormat(new Date(value), "DD MMM YYYY")
				) : (
					<div className="text-center">-</div>
				)}
			</>
		),
	},
];
