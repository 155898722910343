import { Badge } from "react-bootstrap";
import { Column } from "react-table";

import StatusBadge from "../../components/StatusBadge";
import { Currency, dateFormat } from "../../utils";

const CreationTypeBadge = ({ type }: { type: string }) => {
	switch (type) {
		case "quick":
			return <Badge bg="primary">Q</Badge>;
		default:
			return <></>;
	}
};

export const columns: Column[] = [
	{
		accessor: "invoiceCreationType",
		Header: "",
		Cell: ({ value }) => <CreationTypeBadge type={value} />,
	},
	{
		accessor: "id",
		Header: "ID",
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/invoices/${row.original.invoiceReference}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.id}
			</a>
		),
	},
	{
		accessor: "invoiceNumber",
		Header: "Invoice #",
		width: 150,
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/invoices/${row.original.invoiceReference}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.invoiceNumber}
			</a>
		),
	},
	{
		accessor: "invoiceReference",
		Header: "Invoice Ref",
		width: 180,
	},
	{ accessor: "companyShortCode", Header: "Company Short Code", width: 150 },
	{ accessor: "freelancerEmail", Header: "Freelancer Email", width: 200 },
	{ accessor: "freelancerName", Header: "Freelancer Name", width: 200 },
	{ accessor: "clientName", Header: "Client Name", width: 200 },
	{
		accessor: "amount",
		Header: "Amount",
		width: 200,
		Cell: ({ row }: { row: any }) => (
			<>
				{Currency(row.original.amount, {
					symbol: row.original.currency + " ",
				})}
			</>
		),
	},
	{
		accessor: "status",
		Header: "Status",
		width: 150,
		Cell: ({ value }) => <StatusBadge status={value} />,
	},
	{
		accessor: "acceptedDateTime",
		Header: "Accepted Date",
		width: 200,
		Cell: ({ value }) => <>{dateFormat(new Date(value))}</>,
	},
	{
		accessor: "createdDateTime",
		Header: "Created Date",
		width: 200,
		Cell: ({ value }) => <>{dateFormat(new Date(value))}</>,
	},
];
