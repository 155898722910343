import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./modules/auth/config";
import { ReactQueryDevtools } from "react-query/devtools";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import App from "./App";

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

const theme = createTheme({
	typography: { fontFamily: "Poppins" },
});

const history = createBrowserHistory();

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	environment: process.env.REACT_APP_SENTRY_ENV,
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
		}),
		new Sentry.Integrations.Breadcrumbs({
			console: process.env.REACT_APP_SENTRY_ENV !== "local",
		}),
	],
});

ReactDOM.render(
	<MsalProvider instance={msalInstance}>
		<Router>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<App />
					<Toaster
						toastOptions={{
							duration: 7000,
							position: "top-center",
						}}
					/>
				</ThemeProvider>
				<ReactQueryDevtools />
			</QueryClientProvider>
		</Router>
	</MsalProvider>,
	document.querySelector("#root")
);
