import React from "react";
import currency from "currency.js";
import { Badge } from "react-bootstrap";
import { Column } from "react-table";
import { dateFormat } from "../../utils";

export const tableColumns: Column[] = [
	{
		Header: "Cashout Ref",
		accessor: "cashoutRefNumber",
		width: 150,
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/cashouts/${row.original.cashoutRefNumber}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.cashoutRefNumber}
			</a>
		),
	},
	{
		accessor: "invoiceRefNumber",
		Header: "Invoice Ref",
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/invoices/${row.original.invoiceRefNumber}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.invoiceRefNumber}
			</a>
		),
	},
	{
		Header: "User Name",
		accessor: "userName",
		width: 200,
	},
	{
		Header: "Type",
		accessor: (row: any) =>
			row.isB2B2C && row.cashoutType === "invoice" ? (
				<div className="text-capitalize">B2B2C Invoice</div>
			) : (
				<div className="text-capitalize">{row.cashoutType}</div>
			),
		width: 150,
	},
	{
		Header: "Status",
		accessor: "status",
		width: 150,
		Cell: ({ value }) => (
			<Badge pill bg={value === "completed" ? "success" : "primary"}>
				<>{value}</>
			</Badge>
		),
	},
	{ Header: "Currency", accessor: "currency" },
	{
		Header: "Amount",
		accessor: "amount",
		width: 150,
		Cell: ({ value }: any) => <>{currency(value).format()}</>,
	},
	{
		Header: "Bank Acct Holder Name",
		accessor: "bankAccountHolderName",
		width: 200,
	},
	{
		Header: "Bank Code",
		accessor: "bankCode",
		width: 150,
	},
	{
		Header: "Bank Acct No.",
		accessor: "bankAccountNumber",
		width: 230,
	},
	{
		Header: "Requested Payment Date",
		accessor: "createdDateTime",
		width: 200,
		Cell: ({ value }: any) => <>{dateFormat(new Date(value))}</>,
	},
	{
		Header: "Actual Payment Date",
		accessor: "paidDateTime",
		width: 200,
		Cell: ({ value }: any) => (
			<>
				{value ? (
					dateFormat(new Date(value))
				) : (
					<div className="text-center">-</div>
				)}
			</>
		),
	},
];
