import currency from "currency.js";
import { ListGroup, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useGetAdvanceByRef } from "./AdvanceDetails.service";
import { Currency, dateFormat } from "../../../utils";
import StatusBadge from "../../../components/StatusBadge";

const AdvanceDetails = () => {
	const { ref } = useParams<{ ref: string }>();
	const { data } = useGetAdvanceByRef(ref);
	const { replace } = useHistory();

	return !data ? (
		<>Loading...</>
	) : (
		<Modal show onHide={() => replace("/advances")} close>
			<Modal.Header closeButton>
				<Modal.Title>Advance Ref {ref}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup variant="flush">
					<ListGroup.Item>
						<strong>Advance Reference</strong>
						<p>{data.referenceNumber}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Invoice Number</strong>
						<p>
							<a href={`/invoices/${data?.invoiceReference}`} target="_blank">
								{data?.invoiceNumber}
							</a>
						</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Invoice Reference</strong>
						<p>{data.invoiceReference}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Business</strong>
						<p>{data?.businessName || "-"}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Project Title</strong>
						<p>{data?.projectTitle || "-"}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Freelancer Email</strong>
						<p>{data?.freelancerEmail || "-"}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Freelancer Name</strong>
						<p>{data?.freelancerName || "-"}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Type</strong>
						<p>{data?.advanceType === "sponsored" ? "S" : "NS"}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Currency</strong>
						<p>{data.currency}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Eligible Advance Amount</strong>
						<p>{Currency(data.eligibleAdvanceAmount, { symbol: "" })}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Requested Amount</strong>
						<p>{Currency(data.requestedAmount, { symbol: "" })}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Received Amount</strong>
						<p>{Currency(data.receivedAmount, { symbol: "" })}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Fee</strong>
						<p>{Currency(data.advanceFee, { symbol: "" })}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Status</strong>
						<p>
							<StatusBadge
								status={data?.advanceStatus || "-"}
								variant={"primary"}
							/>
						</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Advance Created Date</strong>
						<p>{dateFormat(new Date(data.createdAt))}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Advanced Date</strong>
						<p>{data.updatedAt ? dateFormat(new Date(data.updatedAt)) : "-"}</p>
					</ListGroup.Item>
					<ListGroup.Item>
						<strong>Bill Date</strong>
						<p>
							{data.billDate
								? dateFormat(new Date(data.billDate), "DD MMM YYYY")
								: "-"}
						</p>
					</ListGroup.Item>
				</ListGroup>
				<div className="p-3"></div>
			</Modal.Body>
		</Modal>
	);
};

export default AdvanceDetails;
