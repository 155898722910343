import { dateFormat } from "../../utils";
import type { Column } from "react-table";

export const columns: Column[] = [
	{
		accessor: "id",
		Header: "ID",
		width: 80,
		Cell: ({ row }: { row: any }) => (
			<a
				href={`/users/${row.original.lyteIdIdentifier}`}
				onClick={(e) => e.stopPropagation()}
				target="_blank"
			>
				{row.original.id}
			</a>
		),
	},
	{ accessor: "fullName", Header: "Full Name", width: 220 },
	{ accessor: "email", Header: "Email", width: 220 },
	{ accessor: "freelancerRole", Header: "Freelancer Role", width: 250 },
	{ accessor: "referrerCode", Header: "Referrer User", width: 120 },
	{ accessor: "referrerCode2", Header: "Referrer Code", width: 120 },
	{
		accessor: "createdDateTime",
		Header: "Registration Date",
		width: 200,
		Cell: ({ value }) => <>{dateFormat(new Date(value))}</>,
	},
];
