import React from "react";
import currency from "currency.js";
import { Column } from "react-table";
import { dateFormat, multiDateFormat } from "../../utils";
import StatusBadge from "../../components/StatusBadge";

interface Dictionary {
	[key: string]: string;
}

const handleStatusChecked = (status: string) => {
	const defaultVariant: string = "primary";
	const variants: Dictionary = {
		overdue: "danger",
		partially_paid: "warning",
		paid: "success",
	};
	return status in variants ? variants[status] : defaultVariant;
};

export const tableColumns: Column[] = [
	{
		Header: "ID",
		accessor: "id",
	},
	{
		Header: "Business Name",
		accessor: "businessName",
	},
	{
		Header: "Reference",
		accessor: "billReference",
	},
	{
		Header: "Bill Date",
		accessor: "billDate",
		Cell: ({ value }: any) => <>{dateFormat(new Date(value), "DD MMM YYYY")}</>,
	},
	{
		Header: "Period Date",
		accessor: (row: any) =>
			`${multiDateFormat(
				new Date(row.billPeriodStartDate),
				new Date(row.billPeriodEndDate),
				"DD MMM YYYY"
			)}`,
	},
	{
		Header: "Due Date",
		accessor: "billDueDate",
		Cell: ({ value }: any) => <>{dateFormat(new Date(value), "DD MMM YYYY")}</>,
	},
	{
		Header: "Paid Amount",
		accessor: "billPaidAmount",
		Cell: ({ value }: any) => (
			<div className="text-end">{value ? currency(value).format() : "-"}</div>
		),
	},
	{
		Header: "Total Amount",
		accessor: "billTotalAmount",
		Cell: ({ value }: any) => (
			<div className="text-end">{currency(value).format()}</div>
		),
	},
	{
		Header: "Status",
		accessor: "billStatus",
		Cell: ({ value }) => (
			<StatusBadge status={value} variant={handleStatusChecked(value)} />
		),
	},
];
